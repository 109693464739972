/* Bloco 1 */

#container1-first h1,
#container1-second h1 {
    max-width: 600px;
    font: 2.5rem/2.6rem AmpleSoft;
    /* 4.3vh */
    color: #262626;
    text-align: justify;
}

#container1-first h3,
#container1-second h3 {
    max-width: 600px;
    font: 1.2rem/2rem AmpleSoft;
    /* 2.1vh */
    color: #5d5d5d;
    text-align: justify;
}

#item {
    font: bold 1.2rem/1.5rem AmpleSoft;
    color: #5d5d5d;
    text-align: justify;
}

.bloco-section1 {
    width: 10rem;
    height: 3.8rem;
    background: #0df7b8 0% 0% no-repeat padding-box;
    text-align: justify;
}

.bloco-section1 h2 {
    margin-top: 11px;
    font: bold 2.2rem/2rem AmpleSoft;
    /* 3.8vh/3.5vh */
    color: #3131f9;
}

.bloco-section1 span {
    font: 1.2rem/2rem AmpleSoft;
    color: #5d5d5d;
}

.blocoCiano {
    width: 27px;
    height: 53px;
    background: #0df7b8 0% 0% no-repeat padding-box;
    opacity: 1;
}

.blocoAzul {
    width: 27px;
    height: 53px;
    background: #3131f9 0% 0% no-repeat padding-box;
    opacity: 1;
}

::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
    color: #3131f9;
    font-size: 40px;
}

#passoAPasso .row div {
    max-width: 380px;
    max-height: 380px;
}

@media (max-width: 1150px) {
    #text1-section1 {
        max-width: 75vw;
    }
}

@media (max-width: 650px) {

    #container1-first h1,
    #container1-second h1 {
        font: 2rem/2.6rem AmpleSoft;
    }

    #textoDescricao-section1-bold {
        max-width: 670px;
        font: bold 20px/55px AmpleSoft;
        color: #5d5d5d;
        opacity: 1;
    }

    ::marker {
        font-size: 20px !important;
    }
}

/* 
#container3-section1 {
  max-width: 750px;
  padding-top: 25px;
}

#textoTitulo2-section1 {
  font: 40px/45px AmpleSoft;
  max-width: 650px;
  height: 100;
  color: #262626;
  opacity: 1;
} 

#container4-section1 {
  padding-top: 10px;
  display: flex;
}

#container5-section1 {
  display: flex;
}
*/