.componentBody {
  z-index: 99;
  position: fixed;
  bottom: 3rem;
  right: 2.8rem;
}

.enableComponent {
  display: block;
}

.disableComponent {
  display: none;
}

.componentCollapsed::after {
  content: "Olá! Fale conosco";
  font: 300 20px/27px AmpleSoft;
  color: #ffffff;
}

.componentUncollapsed::after {
  content: "Olá! Fale conosco";
  font: 300 20px/27px AmpleSoft;
  color: #ffffff;
}

.mainMessage {
  margin-top: 6%;
  font: 300 18px/27px AmpleSoft;
  letter-spacing: 0px;
  color: #6a6a6a;
  text-align: center;
}

.whatsappIcon {
  margin-top: 0;
  height: 70px;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
  background: #3131F9;
  /* margin-right: 5px;
  margin-left: 30px;
  margin: 8%; */
}

.avatar {
  margin-left: 15px;
}

/* .avatar::before{
  position: relative;
  width: 10px;
  height: 10px;
  right: 14.5rem;
  bottom: 7.5rem;
  content: "";
  border-radius: 50%;
  background: #22c05e;
  z-index: 99999;
} */

.closeIconClose {
  color: #ffffff;
  background: #22c05e;
  border-radius: 50%;
  margin-top: -45px;
  font-size: 30px !important;
  position: fixed;
  right: 2.8rem;
}

.closeIconOpen {
  color: #ffffff;
  background: #22c05e;
  border-radius: 50%;
  margin-top: -45px;
  transform: rotate(45deg);
  font-size: 30px !important;
  position: fixed;
  right: 2.8rem;
}

.comecarButton {
  width: 100%;
  font: 300 18px/27px AmpleSoft;
  color: #22c05e;
  border: none;
  background: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.mainBackgroundUncollapsed {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 18px;
  opacity: 1;
  width: 300px;
  height: 240px;
}

.mainBackgroundCollapsed {
  background: #ffffff;
  border-radius: 18px;
  width: 300px;
}

.lineTopButton {
  background: rgba(106, 106, 106, 0.2);
  height: 1px;
}

.nomeContato {
  text-align: left;
  font: 15px/27px AmpleSoft;
  letter-spacing: 0px;
  color: #6a6a6a;
}

.descriptionContato {
  text-align: left;
  font: bold 15px/15px AmpleSoft;
  letter-spacing: 0px;
  color: #6a6a6a;
}

.statusContato {
  text-align: left;
  font: 300 12px/15px AmpleSoft;
  margin-top: 13px;
  letter-spacing: 0px;
  color: #6a6a6a;
}

#bodyWhatsapp {
  width: 100%;
  background: #22c05e 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 18px;
  padding-top: 4%;
  padding-bottom: 3%;
}

button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}


@media (max-width: 860px) {
  .collapsing {
    transition-delay: 100ms !important;
    max-height: 0px;
    transition: max-height 250ms ease-in !important;
  }
  
  .componentBody {
    z-index: 99;
    bottom: 3rem;
    right: 1.8rem;
  }

  .toogleIcon {
    display: none;
  }

  .componentCollapsed {
    height: 50px;
    width: 50px;
  }

  .componentCollapsed::after {
    content: "";
  }

  .componentUncollapsed {
    height: 50px;
    width: 50px;
  }

  .mainBackgroundCollapsed {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 18px;
    height: 42px;
    width: 60px;
    transition: all 400ms ease-in ;
  }

  .mainBackgroundUncollapsed {
    height: 240px;
    width: 300px;
    transition: all 400ms ease-out ;
  }

  .componentBody {
    display: absolute;
  }
  
  /* .avatar::before{
    position: fixed;
    width: 10px;
    height: 10px;
    right: 14.4rem;
    bottom: 7.5rem;
    content: "";
    border-radius: 50%;
    background: #22c05e;
    z-index: 99999;
  } */
}

@media (max-width: 600px) {
  .componentBody {
    z-index: 100;
  }
}
