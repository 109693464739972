/* NavBar */

.logoCrediCare {
  min-width: 287px;
  margin-left: 96px;
}

#nav-Simul {
  padding-top: 20px;
  opacity: 1;
}

.menu {
  position: relative;
  width: 100%;
  display: flex;
  text-align: center;
  padding-top: 20px;
  font: normal normal bold 25px AmpleSoft;
  opacity: 1;
  justify-content: space-between;
}
.menu ul {
  list-style: none;
  display: flex;
}
.menu li {
  padding: 10px 20px;
  font-size: 22px;
}
.AssistirVideo {
  display: none;
  font: normal normal bold 22px AmpleSoft;
  margin-top: 15%;
}

.menu-container {
  display: flex;
  height: 107px;
  margin-right: 48px;
}
.menu-container a {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s ease;
}

.colorDestaqueAba {
  color: #0DF7B8 !important;
}

.noColorDestaqueAba {
  color: #ffffff;
}

.menu-container a:hover {
  color: #0df7b8;
}

.play-icon {
  color: rgba(124, 221, 21, 1);
}

.social-icons-write {
  color: #ffffff;
}

.toogleNavBar {
  display: none;
  color: #ffffff;
  margin-left: 145px;
}



@media (max-width: 960px) {
  .logoCrediCare {
    min-width: 137px;
    margin-left: 48px;
  }

  .menu-container {
    display: flex;
    height: 107px;
  }

  .AssistirVideo {
    display: block;
  }

  .menu {
    display: none;
  }

  .toogleNavBar {
    display: flex;
  }
}


@media (max-width: 420px) {
  .logoCrediCare {
    min-width: 20px;
  }
}
