/* NavBar Mobile*/

.backgroundRightMenuDisplay {
  height: 100vh;
  position: fixed;
  display: flex;
  background: linear-gradient(
    180deg,
    rgba(49, 49, 249, 0.96) 0%,
    rgba(255, 255, 255, 0.96) 100%
  );
  z-index: 9999;
}

.backgroundRightMenuNoDisplay {
  display: none;
}

.menu-mobile {
  font: normal normal bold 25px AmpleSoft;
  color: #000000;
  opacity: 1;

}
.menu-mobile ul {
  list-style: none;

}
.menu-mobile li {
  font-size: 22px;
}

.menu-container-mobile {
  display: flex;
  margin-top: 50vh;
  color: #ffffff;

}
.menu-container-mobile a {
  text-decoration: none;
  color: #000000;
}
.menu-container-mobile a:hover {
  color: #3131f9;
}

.line {
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px;
  background: rgba(49, 49, 249, 1);
}

.close-icon {
  margin-right: 20px;
  margin-top: 50px;
  color: #ffffff;
}

.menu-icon {
  color: #000000;
}

.social-icons {
  color: rgba(49, 49, 249, 1);
}

.arrowDownIcon {
  display: none;
  color: rgba(124, 221, 21, 1);
}

@media (max-width: 600px) {
  .arrowDownIcon {
    display: block;
  }
}
