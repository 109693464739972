/* Bloco 2 */

#bloco2 {
    padding-top: 50px;
    width: 100vw;
    min-height: 650px;
}

#coluna1 {
    width: 18.8rem;
}

#coluna1Notebook {
    width: 18.8rem;
}

#coluna1Notebook h1,
#coluna1 h1 {
    font: bold 2.4rem/2.9rem AmpleSoft;
    color: #262626;
    margin-bottom: 50px;
    text-align: justify;

}

#coluna2,
#coluna2Notebook {
    width: 320px;
}

#coluna2Notebook h2,
#coluna2 h2 {
    font: bold 1.6rem/1.8rem AmpleSoft;
    color: #262626;
}

#passoApassoDescricao {
    width: 320px;
    text-align: justify;
}

#passoApassoDescricao span {
    font: 1.3rem/1rem AmpleSoft;
    color: #5d5d5d;
}

.buttonSimul {
    margin-bottom: 80px;
    width: 320px;
    height: 54px;
    background: #7cdd15  no-repeat padding-box;
    border-radius: 12px;
    border-width: 0px;
    color: #ffffff;
}

#coluna3Smartphone {
    max-width: 530px;
}

#coluna3Notebook {
    max-width: 600px;
    margin-bottom: 50px;
}

@media (max-width: 1328px) {
    #coluna3Notebook {
        width: 77vw;
    }

    #coluna3Smartphone {
        width: 75vw;
    }
}

@media (max-width: 1320px) {
    #coluna2, #coluna2Notebook {
        margin-right: 200px;
    }
}

@media (max-width: 730px) {
    #coluna1 {
        width: 75vw;
        margin-right: 0px;
    }

    #coluna1 h1 {
        font: bold 2rem/2.9rem AmpleSoft;
    }

    #coluna2 {
        margin-right: 0px;
    }

    #passoApassoDescricao {
        width: 70.9vw;
    }

    .buttonSimul {
        width: 70.9vw;
    }
}

/* 

#text1-2-section2 {
  width: 320px;
  height: 341px;
  font: bold 40px/45px AmpleSoft;
  color: #262626;
  opacity: 1;
}


#container4-section2 {
  padding-top: 90px;
}

#text4-section2 {
  font: 35px/45px AmpleSoft;
  color: #262626;
  opacity: 1;
}

.container6-section2 {
  margin-bottom: 10%;
}

.bloco-section2 {
  width: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 12px #0000001a;
  border: 0.5px solid #cccccc;
  border-radius: 20px;
  opacity: 1;
}

.comentarioCard-section2 {
  margin-top: 20px;
  padding-right: 25px;
  padding-left: 25px;
  font: 300 16px/30px AmpleSoft;
  color: #6a6a6a;
  opacity: 1;
}

.comentarioCard-section2::before {
  font-family: "Font Awesome 5 Free";
  content: "\f10d";
  font-size: 20px;
  font-weight: 600;
  color: blue;
  height: 50px;
  position: relative;
  top: -12px;
  left: -20px;
}

.nomeCard-section2 {
  font: bold 27px/10px AmpleSoft;
  color: #262626;
  opacity: 1;
}

.cidadeCard-section2 {
  font: 300 12px/24px AmpleSoft;
  color: #262626;
  opacity: 1;
}


.slide-card {
  display: flex;
  width: 100%;
}

.cardsControl {
  display: none !important;
}

.card-bloco {
  border-radius: 20px !important;
  box-shadow: 6px 6px 12px #0000001a !important;
  padding: 1.75rem;
  margin: 3%;
}

@media (max-width: 567px) {
  .carouselWeb {
    display: none;
  }

  .cardsControl {
    display: block !important;
  }

  .card-bloco {
    width: 100%;
    padding: 0.25rem;
    margin: 3%;
  }

  .card-bloco .card-body {
    padding: 0;
  }

  .comentarioCard-section2 {
    margin-top: 20px;
    font: 300 11px/20px AmpleSoft;
  }

  .nomeCard-section2 {
    font: bold 22px/20px AmpleSoft;
  }

  .cidadeCard-section2 {
    font: 300 7px/15px AmpleSoft;
  }

  .comentarioCard-section2::before {
    font-size: 10px;
    top: -6px;
    left: -8px;
  }
}

.buttonPrevNext {
  border: black;
  border-radius: 42px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  font-family: Arial;
  font-size: 17px;
  padding: 5px 5px;
  margin: 3%;
}

@media (min-width: 768px) {
  .buttonPrevNext {
    margin: 0%;
    visibility: hidden;
  }

  .carousel-item {
    margin-right: 0;
  }

  .carousel-inner .active+.carousel-item {
    display: block;
  }

  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left)+.carousel-item {
    transition: none;
    margin-right: auto;
  }
} */