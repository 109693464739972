/* Fonts */
@font-face {
  font-family: AmpleSoft;
  src: url(./fonts/FontsFree-Net-AmpleSoftPro.ttf);
}

@font-face {
  font-family: Andale-Mono;
  src: url(./fonts/Andale-Mono.ttf);
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  display: none;
}