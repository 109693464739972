#nav-Cred {
  position: fixed;
  width: 100vw;
  padding-top: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 2px #00000017;
  opacity: 1;
  z-index: 1;
}

.menu-container2 {
  display: flex;
  height: 107px;
  margin-left: 0px;
  margin-right: 48px;
}
.menu-container2 a {
  text-decoration: none;
  color: #000000;
}
.menu-container2 a:hover {
  color: #3131f9;
}

.colorDestaqueAba2 {
  color: #3131f9 !important;
}

@media (max-width: 770px) {
  .menu-container2 {
    margin-left: 0px;
  }
}

@media (max-width: 600px) {
  #nav-Cred {
    display: none;
  }
}
