/* Header */

#BackgroundHeader {
    background: rgba(49, 49, 249, 0.77);
    height: 100vh;
}

#BackgroundHeader2 {
    background: rgba(0, 0, 0, 0.77);
    height: 100vh;
}

#MainHeader {
    background-image: url("../img/4e9084123f8b5ea00e2620f7d5b65ced.png");
    background-size: cover;
    background-position: right;
    position: relative;
    height: 100vh;
    z-index: 99 !important;
}

#SecondHeader {
    background-image: url("../img/8bbd4981ec104f70f0f2088e923c028a.png");
    background-size: cover;
    position: relative;
    height: 100vh;
    z-index: 99 !important;
}

#container0Header {
    padding-top: 10vh;
}

#textHeader0 {
    max-height: 30px;
    text-align: left;
    font: bold 2vh/2vh AmpleSoft;
    color: #0df7b8;
}

#container1Header {
    padding-top: 40px;
}

#textHeader1 {
    max-width: 400px;
    font: bold 5vh/5vh AmpleSoft;
    color: #ffffff;
}

#textHeader2 {
    max-width: 65vh;
    max-height: 173px;
    font: 4.1vh/4.1vh AmpleSoft;
    color: #ffffff;
}

.alignbutton {
    margin-top: 3px;
}

.button {
    width: 280px;
    height: 50px;
    background: #7cdd15  no-repeat padding-box;
    border-radius: 12px;
    color: #ffffff;
    position: absolute;
    bottom: 100px;
    padding: 0px;
}

.buttonSimul span,
.button span{
    font: bold 20px AmpleSoft;
    position: relative;
    height: 24px;
}

.buttonAlign {
    text-align: center;
    align-items: center;
    height: 24px;
}

.socials {
    margin-top: 150px;
    position: absolute;
    color: white;
    right: 5%;
    bottom: 100px;
}

.botaoVideo {
    display: none;
    background-color: Transparent;
    background-repeat: no-repeat;
    font: 21px AmpleSoft;
    color: #ffffff;
    border: none;
    cursor: pointer;
    overflow: hidden;
    padding-top: 15%;
}

.iconArrowDown {
    position: absolute;
    bottom: 12px;
    right: 50%;
    left: 50%;

}

.bodyHeader {
    margin-right: 48px;
    margin-left: 48px;
}

@media (max-width: 1200px) {
    #textHeader1 {
        max-width: 600px;
        font: bold 4vh/4vh AmpleSoft;
    }

    #textHeader2 {
        font: 3.1vh/3.1vh AmpleSoft;
    }
}

@media (max-width: 960px) {
    .bodyHeader {
        margin-right: 0px;
        margin-left: 0px;
    }
}

@media (max-height: 600px) {
    .button {
        display: none;
    }
}

@media (max-width: 600px) {
    .botaoVideo {
        display: block;
    }

    #container0Header {
        padding-top: 30px;
    }

    #textHeader0 {
        display: none;
    }

    #textHeader1 {
        text-align: right;
        max-width: 500px;
    }

    #textHeader2 {
        text-align: right;
    }

    #textHeader3 {
        text-align: right;
    }

    .button {
        display: none;
    }

    .socials {
        display: none;
    }

    .smileIcon {
        display: none;
    }
}