/* Footer */

#bloco-footer {
  height: 270px;
  width: 100vw;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  opacity: 1;
}

.centerLogoFooter {
  margin-top: 100px;
  margin-bottom: 20px;
}

.blocoCianoFooter {
  width: 63px;
  height: 27px;
  background: #0df7b8 0% 0% no-repeat padding-box;
  opacity: 1;
}

.blocoAzulFooter {
  width: 63px;
  height: 27px;
  background: #3131f9 0% 0% no-repeat padding-box;
  opacity: 1;
}